<template>   
  <div class="it">
    <div class="av skl"></div>
    <div class="nm skl"></div>
    <div class="us skl"></div>
    <div class="sd skl"></div>
    <div class="dt skl"></div>
  </div>   
</template>

 
  

<style scoped>
   
.it{
  display: flex;  
  justify-content: flex-start;
  flex-direction: column;  
  margin-bottom: 10px;  
  width: 180px;
  margin-right: 12px;
}
.it .av{
  width: 100%;  
  margin-right: 10px;
  flex-shrink: 0; 
  height: 122px;
}
.it .nm{
  width: 90%; 
  height: 16px; 
  margin-top: 5px; 
}
.it .us{
  width: 60%; 
  height: 16px; 
  margin-top: 5px; 
}
.it .sd{
  width: 50%; 
  height: 16px; 
  margin-top: 5px; 
}
.it .dt{
  width: 30%; 
  height: 16px; 
  margin-top: 5px; 
}


@media only screen and (max-width: 648px) {
    .it{
        width: calc(33.3333% - 14px);
        min-width: calc(33.3333% - 14px);
    }
    .it .av{
        height: 104px;
    }
}

@media only screen and (max-width: 500px) {
    .it{
        width: calc(50% - 20px);
        min-width: calc(50% - 20px);
    }
    .it .av{
        height: 134px;
    }
}
@media only screen and (max-width: 400px) {
    .it .av{
        height: 104px;
    }
}
@media only screen and (max-width: 350px) {
    .it .av{
        height: 94px;
    }
}

</style>
